import { route } from "$crown/stores";
import type { ErrorResponseMessage } from "@crown/data";
import { init as initializeCrown } from "@crown/jewels/client";
import { addErrorListener } from "@crown/logging";
import { logToConsole } from "@crown/logging/src/log-to-console";
import { track } from "@crown/tracking";
import { registerImageScaler } from "@crown/ui/Image/image-scalers";
import { writable } from "svelte/store";
import {
  base64ImageScaler,
  imgIxImageScaler,
  storyblokImageScaler,
} from "../src/image-sizing";
import type { VirtualPageviewEvent } from "../src/tracking/events/page";
import { createCartStore } from "./cart/cart-store";
import { createShoppingListStore } from "./cart/shopping-list-store";
import {
  CAMPAIGN_LABELS_CONTEXT_KEY,
  CART_CONTEXT_KEY,
  LOCALIZATION_CONTEXT_KEY,
  SHOPPING_LIST_CONTEXT_KEY,
} from "./context-keys";
import { getLocalizationOnClientInit } from "./localization";
import { initializePosthog } from "./posthog/posthog";
import { setUtm } from "./tracking/utm";

addErrorListener(logToConsole);

const ignoreCodes = ["notLoggedIn"];

export function isWorthLogging(error: ErrorResponseMessage) {
  const message = error.message || "";

  const isNetworkProblem =
    ignoreCodes.includes(error.code || "") ||
    /* chrome */
    message.includes("Failed to fetch") ||
    message.includes("getaddrinfo ENOTFOUND") ||
    /* firefox */
    message.includes("NetworkError") ||
    /* safari */
    message.includes("nedkopplad") ||
    message == "Not found" ||
    /* mobile safari */
    message.includes("Load failed") ||
    message.includes("avbruten");

  return !isNetworkProblem;
}

registerImageScaler(storyblokImageScaler);
registerImageScaler(imgIxImageScaler);
registerImageScaler(base64ImageScaler);

// track virtual_pageviews client-side on route change.
let previousLocation: URL;

route.subscribe(({ url }) => {
  if (url && previousLocation) {
    const virtualPageView: VirtualPageviewEvent = {
      type: "virtual_pageview",
      title: document.title,
      location: url.toString(),
      previous_location: previousLocation.toString(),
    };
    track(virtualPageView);
  }
  previousLocation = url;
});

const basePath = new URL(document.baseURI).pathname;

initializeCrown(
  new Map<string, any>([
    [LOCALIZATION_CONTEXT_KEY, getLocalizationOnClientInit()],
    [CART_CONTEXT_KEY, createCartStore({ basePath })],
    [SHOPPING_LIST_CONTEXT_KEY, createShoppingListStore({ basePath })],
    // these are initialized by the $layout
    [CAMPAIGN_LABELS_CONTEXT_KEY, writable({})],
  ])
);

if (window.location.search) {
  setUtm(window.location.search);
}

setTimeout(initializePosthog, 2000);
