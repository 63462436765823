import type { Commercetools } from "@crown/commercetools";
import type { ClientPaymentRequest } from "@crown/commercetools/types";
import type { SWR, SWRStore } from "@crown/data";
import { logError } from "@crown/logging";
import type { Crown } from "@crown/types";
import { getContext } from "svelte";
import { get } from "svelte/store";
import { CART_CONTEXT_KEY } from "../context-keys";
import type { LocaleConfig } from "../locale";
import { setCartClientMetadata } from "../tracking/client-metadata";
import type { Storefront } from "../types/Storefront";
import { getProductListStore } from "./product-list";
import { normalizePath } from "../url-builder";

export const checkoutLocations = ["hatstore", "fitted-world"] as const;
export const customerTypes = ["private", "company"] as const;

export const invalidCustomizedTextChars =
  /[^0-9a-zA-ZåäöÅÄÖæøåÆØÅáéíóúüñß¿¡!"\#\$%&'\(\)\*\+,-\./\\:;<=>\?@\[\]\{\}_´|~^\s£€\u00A9\u00AE«» ]/g;
export const invertedInvalidCustomizedTextChars =
  /[0-9a-zA-ZåäöÅÄÖæøåÆØÅáéíóúüñß¿¡!"\#\$%&'\(\)\*\+,-\./\\:;<=>\?@\[\]\{\}_´|~^\s£€\u00A9\u00AE«» ]/g;
export const customizedTextMaxLength = 10;

let cartStoreCache: Storefront.CartStore | undefined = undefined;

export function getCartStore(): Storefront.CartStore {
  const cartStore = getContext(CART_CONTEXT_KEY) as Storefront.CartStore;

  if (import.meta.env.DEV && !cartStore) {
    throw new Error("Cart not present in context.");
  }

  return cartStore;
}

export function createCartStore({
  bypassCache,
  swr,
  basePath,
}: {
  bypassCache?: boolean;
  swr?: SWR;
  basePath: string;
}): Storefront.CartStore {
  if (!cartStoreCache || bypassCache) {
    const url = normalizePath(basePath, "/resources/cart");
    cartStoreCache = getProductListStore(url, decorateCart, swr);
  }

  return cartStoreCache;
}

function findExistingItem(
  item: Storefront.CartItem | Crown.Cart.Actions.AddItem,
  existingItems: Storefront.CartItem[]
) {
  if ("type" in item && item.type === "standard") {
    const sizeSku = item.product.sku;
    const { customizedText, patch } = item;
    return existingItems.find(
      (item) =>
        item.type === "standard" &&
        item.product.sku === sizeSku &&
        item.customizedText == customizedText &&
        item.patch?.id == patch?.id
    );
  } else {
    return existingItems.find(
      (existingItem) => existingItem.itemId === item.itemId
    );
  }
}

function onCartCreated(cartStore: Storefront.CartStore, locale: LocaleConfig) {
  return setCartClientMetadata(cartStore, locale);
}

export function decorateCart(
  cart: Crown.Cart.Store<Storefront.CartItem, Storefront.Cart>,
  cartStore: SWRStore<Storefront.Cart>
): Storefront.CartStore {
  return {
    ...cart,
    async add(
      item: Storefront.CartItem | Crown.Cart.Actions.AddItem
    ): Promise<void> {
      let { data } = get(cart);

      if (!data) {
        await cart.load();

        data = get(cart).data!;
      }

      let wasEmpty = data.items.length === 0;

      const existingItem = findExistingItem(item, data.items);

      if (existingItem) {
        return cart.update({
          ...existingItem,
          quantity: existingItem.quantity + item.quantity,
        });
      } else {
        await cart.add(item);

        // the cart doesn't actually exist until an item is added.
        // this created the cart.
        if (wasEmpty) {
          await onCartCreated(this, data.locale);
        }
      }
    },
    async setCustomerType(customerType) {
      await cartStore.mutate({
        update: (cart) => cart,
        method: "POST",
        subpath: "/customer-type",
        body: {
          customerType,
        },
      });
    },
    async prepareCheckout() {
      await cartStore.mutate({
        update: (cart) => cart,
        method: "POST",
        subpath: "/prepare",
        /* if something already went wrong with the cart there is no point to run prepare */
        requireLoaded: true,
      });
    },
    async updateAddress(addresses: {
      billing?: Commercetools.Address;
      shipping?: Commercetools.Address;
      newsletterSignup: boolean;
    }) {
      await cartStore.mutate({
        update: (cart) => cart,
        method: "PUT",
        subpath: `/address`,
        body: addresses,
      });
    },
    async onIngridChange() {
      await cartStore.mutate({
        update: (cart) => cart,
        method: "PUT",
        subpath: `/on-ingrid-change`,
        body: {},
      });
    },
    async addCode(code: string) {
      return cartStore.mutate({
        update: (cart) => cart,
        method: "POST",
        subpath: `/code`,
        body: { code: code.trim() },
      });
    },
    async removeDiscountCode(id: string) {
      return cartStore.mutate({
        update: (cart) => cart,
        method: "DELETE",
        subpath: `/discount-code`,
        body: { id },
      });
    },
    async removeGiftCard() {
      return cartStore.mutate({
        update: (cart) => cart,
        method: "DELETE",
        subpath: `/gift-card`,
        body: {},
      });
    },
    async makePayment(paymentRequest: ClientPaymentRequest) {
      return cartStore.mutate({
        update: (cart) => cart,
        method: "POST",
        subpath: `/payment`,
        body: paymentRequest,
      });
    },
    async makeZeroPayment() {
      return cartStore.mutate({
        update: (cart) => cart,
        method: "POST",
        subpath: `/zero-payment`,
      });
    },
    async submitAdditionalPaymentDetails(
      details: Commercetools.PaymentDetails,
      payment: Commercetools.Payment
    ) {
      return cartStore.mutate({
        update: (cart) => cart,
        method: "POST",
        subpath: `/payment-details?payment=${payment.id}`,
        body: details,
      });
    },
    async setClientMetadata({
      deviceType,
      utm,
    }: {
      deviceType: "MOBILE" | "DESKTOP";
      utm?: string;
    }) {
      return cartStore.mutate({
        update: (cart) => cart,
        method: "POST",
        subpath: `/client-metadata`,
        body: {
          deviceType,
          utm,
        },
      });
    },
    onPreAutoRevalidate: (callback: () => void) =>
      cartStore.onPreAutoRevalidate(callback),
    onPostAutoRevalidate: (callback: () => void) =>
      cartStore.onPostAutoRevalidate(callback),
    revalidate: () => cartStore.revalidate().catch(logError),
  };
}
