import type { posthog as posthogInstance, Properties } from "posthog-js";
import { getPosthogApiKey } from "../env-vars";
import { writable } from "svelte/store";

let posthog: typeof posthogInstance;

let onFeatureFlags: () => void;
let init: Promise<void> | null = new Promise(
  (resolve) => (onFeatureFlags = resolve)
);
let isInitialized = false;

export function initializePosthog() {
  const apiKey = getPosthogApiKey();

  if (apiKey) {
    import("posthog-js").then(({ default: all }) => {
      posthog = all;

      posthog.init(apiKey, {
        api_host: "https://eu.posthog.com",
        autocapture: false,
      });

      posthog.onFeatureFlags((featureFlags) => {
        onFeatureFlags();

        isInitialized = true;
      });
    });
  }
}

export function capturePosthog(event: string, properties?: Properties) {
  posthog?.capture(event, properties);
}

export function getFeatureFlag(feature: string) {
  const store = writable<boolean | string | undefined>(
    posthog?.getFeatureFlag(feature)
  );

  if (!isInitialized) {
    init?.then(() => store.set(posthog!.getFeatureFlag(feature)));
  }

  return store;
}
